import { IMG_CMS } from "../../../api/constants";

export default function InseratImgRow(props: any) {
  return (
    <div className="w-full max-w-screen-lg flex gap-2 justify-center pt-8 overflow-hidden">
      {props.imgs.map((item: any, index: number) => (
        <img
          src={IMG_CMS + item.attributes.formats.small.url}
          alt=""
          onClick={props.openModal}
          className="w-24 h-14 rounded-md cursor-pointer"
        />
      ))}
    </div>
  );
}
