import { HardFact } from "../../InseratePage/components/HardFact";
import { ReactComponent as SPACE } from "../../../assets/Space.svg";
import { ReactComponent as FLOOR } from "../../../assets/OfficeBuilding.svg";
import { ReactComponent as ROOM } from "../../../assets/House.svg";
import { ReactComponent as WHEEL } from "../../../assets/Wheelchair.svg";
import { ReactComponent as MAPPIN } from "../../../assets/MapPin.svg";
import { ReactComponent as MONEY } from "../../../assets/Money.svg";
import { ReactComponent as MONEY2 } from "../../../assets/Money2.svg";

export default function InseratHardFacts(props: any) {
  return (
    <div className="flex flex-col max-w-screen-xl items-center">
      <p className="flex items-center gap-2 text-base sm:text-lg md:text-xl font-semibold pt-8">
        <span>
          <MAPPIN className="h-12 w-12" />
        </span>
        {props.adresse}
      </p>
      <div className="w-full border-b my-4 border-black" />
      <div className="flex justify-center gap-8 w-full p-4">
        {props.Preis && (
          <>
            <HardFact
              title={" €"}
              number={props.Preis.toLocaleString("de-DE")}
              icon={<MONEY className="w-12 h-12 " />}
            />
            <div className="border-l h-16 border-gray-200" />
          </>
        )}
        {props.Zimmer && (
          <HardFact
            icon={<ROOM className="w-12 h-12" />}
            number={props.Zimmer}
            title={" Zimmer"}
          />
        )}
        <div className="border-l h-16 border-gray-200" />
        {props.Wohnflaeche && (
          <HardFact
            icon={<SPACE className="w-12 h-12" />}
            number={props.Wohnflaeche}
            title={" m²"}
          />
        )}
        {props.Quadratmeterpreis && (
          <>
            <div className="border-l h-16 border-gray-200" />
            <HardFact
              title={" € / m²"}
              number={props.Quadratmeterpreis.toLocaleString("de-DE")}
              icon={<MONEY2 className="w-12 h-12 " />}
            />
          </>
        )}
        <div className="border-l h-16 border-gray-200" />
        {props.Stockwerk && (
          <HardFact
            icon={<FLOOR className="w-12 h-12 font-light" />}
            number={props.Stockwerk}
            title={". Stock"}
          />
        )}
        {props.Barrierefrei && (
          <>
            <div className="border-l h-16 border-gray-200" />
            <HardFact
              title={"Barrierefrei"}
              icon={<WHEEL className="w-12 h-12 " />}
            />
          </>
        )}
      </div>
    </div>
  );
}
