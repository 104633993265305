import React from "react";

export default function BaseInput(props: any) {
  return (
    <input
      type="text"
      placeholder={props.placeholder}
      onChange={props.onChange}
      className={`input input-bordered w-full input-sm input-primary ${props.classProps}`}
      {...props}
    />
  );
}
