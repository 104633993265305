import { BlocksContent, BlocksRenderer } from "@strapi/blocks-react-renderer";
import useCookie from "react-use-cookie";
import { MAPS } from "../../..";
export default function InseratTextAndMap(props: any) {
  const [mapsCookie, setMapsCookie] = useCookie(
    MAPS.CONSENT_COOKIE_NAME,
    MAPS.CONSENT_COOKIE_VALUE.disabled
  );

  const content: BlocksContent = props.Beschreibung;
  return (
    <>
      <p className="text-start max-w-screen-xl py-8">
        <BlocksRenderer content={content ?? []} />
      </p>
      <div className="w-full flex justify-center">
        {props.GoogleIframe &&
          mapsCookie === MAPS.CONSENT_COOKIE_VALUE.disabled && (
            <><div className="flex p-0 w-full order-2 h-80 bg-base-200">
            <button
              onClick={() => setMapsCookie(MAPS.CONSENT_COOKIE_VALUE.enabled)}
              className="m-auto bg-secondary cursor-pointer text-white rounded-2xl py-2 px-6 font-normal  active:scale-pressed duration-100 ease-in-out text-center "
            >
              Google Maps Karte anzeigen
            </button>
          </div><p className="pt-2">{props.Lage}</p></>
            )}

        {props.GoogleIframe &&
          mapsCookie === MAPS.CONSENT_COOKIE_VALUE.enabled && (
            <div className="max-w-screen-xl py-8 w-full">
              <iframe
                title="Google Map Location"
                className="w-full h-80"
                src={props.GoogleIframe}
                loading="lazy"
              ></iframe>
              <p className="pt-2">{props.Lage}</p>
            </div>
          )}
      </div>
    </>
  );
}
