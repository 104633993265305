import React from "react";
import { getHeroInserate, getInserate } from "../../api/apiCalls";
import { useQuery } from "@tanstack/react-query";
import ErrorPage from "../404/ErrorPage";
import Loading from "../../components/common/Loading";
import Hero from "../Homepage/components/Hero";
import AllElements from "./components/AllElements";
import Kontakt from "../Homepage/components/Kontakt";

export default function InseratePage() {
  const {
    error: heroError,
    data: hero,
    isLoading: heroLoading,
  } = useQuery({
    queryKey: ["heroDataInserate"],
    queryFn: getHeroInserate,
  });
  const {
    error: inserateError,
    data: inserate,
    isLoading: inserateLoading,
  } = useQuery({
    queryKey: ["inserateData"],
    queryFn: getInserate,
  });

  return (
    <main className="w-full h-full flex flex-col items-center justify-center">
      {(heroError || inserateError) && <ErrorPage />}
      {(heroLoading || inserateLoading) && <Loading />}
      {hero && inserate && (
        <>
          <Hero data={hero} />
          <AllElements elements={inserate} />
          <Kontakt
            data={{
              Headline: "Ihr nächstes Objekt gefunden?",
              Subheadline:
                "Bei Fragen oder Anliegen zum Objekt kontaktieren Sie uns gern direkt!",
            }}
            classProps="mb-16"
          />
        </>
      )}
    </main>
  );
}
