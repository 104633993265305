import React from "react";

export default function BaseHeadline(props: any) {
  return (
    <div className={`flex flex-col  ${props.classProps}`}>
      <div className="w-fit">
        <h3 className="font-headline text-3xl sm:text-4xl md:text-5xl font-bold">
          {props.Headline}
        </h3>
        <div
          className={`w-3/4 border-t-2 border-secondary mt-2 ${props.lineProps}`}
        />
      </div>
      <h5 className="font-headline text-xl sm:text-2xl pt-1">
        {props.Subheadline}
      </h5>
    </div>
  );
}
