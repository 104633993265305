import { IMG_CMS } from "../../../api/constants";
import { HardFact } from "./HardFact";
import { ReactComponent as SPACE } from "../../../assets/Space.svg";
import { ReactComponent as FLOOR } from "../../../assets/OfficeBuilding.svg";
import { ReactComponent as ROOM } from "../../../assets/House.svg";
import { ReactComponent as WHEEL } from "../../../assets/Wheelchair.svg";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export default function AllElements(props: any) {
  return (
    <section className="w-full flex-wrap flex max-w-screen-2xl h-fit px-4 pt-16 sm:pt-24 pb-16 md:pt-32 gap-8 items-center justify-center">
      {props.elements?.map((item: any) => (
        <SinglePreviewElement
          id={item.id}
          img={
            IMG_CMS +
            (item?.attributes?.Bilder?.data
              ? item?.attributes?.Bilder?.data[0]?.attributes?.url
              : "")
          }
          headline={item.attributes?.Headline}
          Adresse={item.attributes?.Adresse}
          Zimmer={item.attributes?.Zimmer}
          Wohnflaeche={item.attributes?.Wohnflaeche}
          Barrierefrei={item.attributes?.Barrierefrei}
          Stockwerk={item.attributes?.Stockwerk}
        />
      ))}
    </section>
  );
}

function SinglePreviewElement(props: any) {
  const navigate = useNavigate();
  return (
    <motion.div
      whileHover={{
        scale: 1.03,
      }}
      onClick={() => navigate("/inserate/" + props.id)}
      className="flex p-8 cursor-pointer rounded-md shadow-lg w-full md:w-[28rem] h-fit items-center flex-col"
    >
      <div className="flex flex-col sm:flex-row gap-2 items-center">
        <div className="avatar self-center">
          <div className="w-16 h-16 sm:w-24 rounded-full sm:h-24">
            <img src={props.img} alt={props.Headline} />
          </div>
        </div>
        <div className="flex flex-col justify-center w-full h-full">
          <h4 className="font-semibold">{props.headline}</h4>
          <h5 className="text-sm text-gray-400 truncate">{props.Adresse}</h5>
        </div>
      </div>
      <div className="flex justify-evenly w-full p-4">
        <HardFact
          icon={<ROOM className="w-6 h-6" />}
          number={props.Zimmer}
          title={" Zimmer"}
          smallText
        />
        <HardFact
          icon={<SPACE className="w-6 h-6" />}
          number={props.Wohnflaeche}
          title={" m²"}
          smallText
        />
        <HardFact
          icon={<FLOOR className="w-6 h-6" />}
          number={props.Stockwerk}
          title={". Stock"}
          smallText
        />
        {props.Barrierefrei && (
          <HardFact
            title={"Barrierefrei"}
            icon={<WHEEL className="w-6 h-6" />}
            smallText
          />
        )}
      </div>
    </motion.div>
  );
}
