import { BlocksRenderer } from "@strapi/blocks-react-renderer";

export const CustomBlocksRenderer = (props: any) => {
  if (!props.content) return null;

  return (
    <>
      {props.content.map((block: any, index: any) => {
        // Custom logic for headings

        if (block.type === "heading" && block.level === 1) {
          return (
            <h1 key={index} className="text-5xl font-bold py-4">
              {block.children[0].text}
            </h1>
          );
        } else if (block.type === "heading" && block.level === 2) {
          return (
            <h2 key={index} className="text-4xl font-bold ">
              {block.children[0].text}
            </h2>
          );
        } else if (block.type === "heading" && block.level === 3) {
          return (
            <h3 key={index} className="text-3xl font-bold pt-6 pb-4">
              {block.children[0].text}
            </h3>
          );
        } else if (block.type === "heading" && block.level === 4) {
          return (
            <h4 key={index} className="text-2xl font-bold pt-6 pb-4">
              {block.children[0].text}
            </h4>
          );
        } else if (block.type === "heading" && block.level === 5) {
          return (
            <h5 key={index} className="text-xl font-bold pt-4 pb-2">
              {block.children[0].text}
            </h5>
          );
        } else if (block.type === "heading" && block.level === 6) {
          return (
            <h6 key={index} className="text-lg font-semibold pt-4 pb-2">
              {block.children[0].text}
            </h6>
          );
        }

        // Default rendering for other components using BlocksRenderer
        return <BlocksRenderer key={index} content={[block]} />;
      })}
    </>
  );
};
