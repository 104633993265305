import { BlocksContent, BlocksRenderer } from "@strapi/blocks-react-renderer";
import { IMG_CMS } from "../../../api/constants";

export default function ReferenzDetailObj(props: any) {
  const content: BlocksContent = props.data.attributes.Beschreibung;
  return (
    <section
      className={`w-full max-w-screen-xl h-fit py-8 sm:py-16 flex gap-8 px-4 items-center justify-center flex-col ${
        props.index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
      }`}
    >
      <div className="flex flex-col justify-center w-full md:w-3/4 lg:w-1/2">
        <p className="text-gray-400">{props.data.attributes.Adresse}</p>
        <h4 className="text-xl font-headline font-bold">
          {props.data.attributes.Headline}
        </h4>
        <p className="text-sm">
          <BlocksRenderer content={content ?? []} />
        </p>
      </div>
      <div className="flex flex-wrap justify-center gap-2 lg:w-[24.5rem]">
        {props.data?.attributes?.Bilder?.data?.map((item: any) => (
          <img
            src={
              IMG_CMS +
              (item.attributes?.formats?.medium?.url ?? item?.attributes?.url)
            }
            alt={item.attributes?.name}
            className="w-32 h-24 sm:w-48 sm:h-32 object-cover"
          />
        ))}
      </div>
    </section>
  );
}
