import Marquee from "react-fast-marquee";
import BaseHeadline from "../../../components/common/BaseHeadline";
import { IMG_CMS } from "../../../api/constants";

export default function Referenzen(props: any) {
  return (
    <>
      {props.referenzen !== undefined && (
        <section
          id="referenzen"
          className={`w-full flex flex-col justify-center items-center py-16 sm:py-20 md:py-24 lg:py-32 bg-primary text-base-100`}
        >
          <div className="max-w-screen-xl px-4 sm:px-8 w-full">
            <BaseHeadline Headline="Referenzen" />
          </div>
          <Marquee direction="right" className="p-8" loop={0} gradient={false}>
            {props.referenzen.map((item: any) => (
              <Referenz
                adresse={item?.attributes?.Adresse}
                Headline={item?.attributes?.Headline}
                img={
                  IMG_CMS + item?.attributes?.Bilder?.data[0]?.attributes?.url
                }
              />
            ))}
            {props.referenzen.map((item: any) => (
              <Referenz
                adresse={item?.attributes?.Adresse}
                Headline={item?.attributes?.Headline}
                img={
                  IMG_CMS + item?.attributes?.Bilder?.data[0]?.attributes?.url
                }
              />
            ))}
          </Marquee>
          {/* <Marquee direction="left" className="px-8" loop={0} gradient={false}>
            {props.referenzen.map((item: any) => (
              <Referenz
                adresse={item?.attributes?.Adresse}
                Headline={item?.attributes?.Headline}
                img={
                  IMG_CMS + item?.attributes?.Bilder?.data[0]?.attributes?.url
                }
              />
            ))}
            {props.referenzen.map((item: any) => (
              <Referenz
                adresse={item?.attributes?.Adresse}
                Headline={item?.attributes?.Headline}
                img={
                  IMG_CMS + item?.attributes?.Bilder?.data[0]?.attributes?.url
                }
              />
            ))}
          </Marquee> */}
        </section>
      )}
    </>
  );
}

function Referenz(props: any) {
  return (
    <div className="w-80 h-48 bg-base-200 shadow-lg rounded-lg p-8 flex flex-row mx-4 text-base-300 items-center">
      <div className="avatar">
        <div className="w-20 rounded-full">
          <img src={props.img} alt={props.Headline} />
        </div>
      </div>
      <div className="flex flex-col border-l border-secondary ml-2 px-2">
        <h3 className="text-xs text-gray-400 line-clamp-2 ">{props.adresse}</h3>
        <h4 className="text-sm font-semibold line-clamp-2">{props.Headline}</h4>
      </div>
    </div>
  );
}
