import { BlocksContent } from "@strapi/blocks-react-renderer";
import ErrorPage from "../404/ErrorPage";
import Loading from "../../components/common/Loading";
import { useQuery } from "@tanstack/react-query";
import { getDatenschutz } from "../../api/apiCalls";
import { CustomBlocksRenderer } from "../../components/layout/customComponents";

export default function Datenschutz() {
  const { error, data, isLoading } = useQuery({
    queryKey: ["datenschutz"],
    queryFn: getDatenschutz,
  });
  const content: BlocksContent = data;

  return (
    <>
      {isLoading && <Loading />}
      {error && <ErrorPage />}
      {!isLoading && !error && data && (
        <>
          <div className="max-w-screen-xl px-8 py-6 mx-auto mt-24 w-full">
            <CustomBlocksRenderer content={content} />
          </div>
        </>
      )}
    </>
  );
}
