import { getSingleInserat } from "../../api/apiCalls";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Loading from "../../components/common/Loading";
import ErrorPage from "../404/ErrorPage";
import InseratHero from "./components/InseratHero";
import InseratHardFacts from "./components/InseratHardFacts";
import InseratTextAndMap from "./components/InseratTextAndMap";
import InseratImgRow from "./components/InseratImgRow";
import BaseImgSliderModal from "../../components/common/BaseImgSliderModal";
import { useState } from "react";
import Kontakt from "../Homepage/components/Kontakt";

export default function InseratDetailPage() {
  const { id } = useParams();
  const { data, isLoading, error } = useQuery({
    queryKey: ["singleInserat" + id],
    queryFn: () => getSingleInserat(id),
  });

  const [modalOpen, setmodalOpen] = useState(false);
  const handleOpenModal = () => {
    setmodalOpen(true);
  };

  return (
    <>
      {isLoading && <Loading />}
      {error && <ErrorPage />}
      {!isLoading && !error && data && (
        <main className="w-full h-full flex flex-col items-center justify-center py-24 px-4 sm:px-8">
          <InseratHero
            video={data.Video?.data?.attributes?.url}
            Headline={data.Headline}
            description={data.Kurzbeschreibung}
            imgUrl={
              data?.Bilder?.data[0]?.attributes?.formats?.large?.url ??
              data?.Bilder?.data[0]?.attributes?.url
            }
          />
          <InseratHardFacts
            adresse={data.Adresse}
            Zimmer={data.Zimmer}
            Preis={data.Kaufpreis}
            Quadratmeterpreis={data.Quadratmeterpreis}
            Wohnflaeche={data.Wohnflaeche}
            Stockwerk={data.Stockwerk}
            Barrierefrei={data.Barrierefrei}
          />
          <InseratImgRow imgs={data.Bilder.data} openModal={handleOpenModal} />
          <InseratTextAndMap
            Beschreibung={data.Beschreibung}
            Lage={data.Lage}
            GoogleIframe={data.GoogleIframe}
          />
          <Kontakt
            data={{
              Headline: "Ihr nächstes Objekt gefunden?",
              Subheadline:
                "Bei Fragen oder Anliegen zum Objekt kontaktieren Sie uns gern direkt!",
            }}
            topicContact={data.Adresse}
            classProps="!pt-24 !px-0 sm:px-8"
          />
          <BaseImgSliderModal
            modalOpen={modalOpen}
            imgs={data.Bilder.data}
            closeModal={() => setmodalOpen(false)}
          />
        </main>
      )}
    </>
  );
}
