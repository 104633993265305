import { BlocksContent } from "@strapi/blocks-react-renderer";
import ErrorPage from "../404/ErrorPage";
import Loading from "../../components/common/Loading";
import { useQuery } from "@tanstack/react-query";
import { getImpressum } from "../../api/apiCalls";
import { CustomBlocksRenderer } from "../../components/layout/customComponents";

export default function Impressum() {
  const { error, data, isLoading } = useQuery({
    queryKey: ["impressum"],
    queryFn: getImpressum,
  });
  const content: BlocksContent = data;
  return (
    <>
      {isLoading && <Loading />}
      {error && <ErrorPage />}
      {!isLoading && !error && data && (
        <>
          <div className="max-w-screen-xl px-8 py-6 mx-auto mt-24  w-full">
            <CustomBlocksRenderer content={content ?? []} />
          </div>
        </>
      )}
    </>
  );
}
