import { Carousel, Modal } from "antd";
import { IMG_CMS } from "../../api/constants";

export default function BaseImgSliderModal(props: any) {
  return (
    <Modal
      centered
      open={props.modalOpen}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      //   width={1000}
      className="max-w-screen-lg !w-[80vw] h-[70vh]"
      onCancel={props.closeModal}
    >
      <div className="pt-8 pb-4 w-full">
        <Carousel
          draggable
          infinite
          autoplay
          autoplaySpeed={8000}
          arrows
          className="w-full gap-2"
        >
          {props.imgs?.map((item: any, index: number) => (
            <div className="px-2 w-[40rem]">
              <img
                className="w-[80vw] h-[60vh] rounded-md object-cover"
                alt=""
                src={
                  IMG_CMS +
                  (item?.attributes?.formats?.large?.url ??
                    item?.attributes?.url)
                }
              />
            </div>
          ))}
        </Carousel>
      </div>
    </Modal>
  );
}
