import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import BaseLayout from "./components/layout/BaseLayout";
import Homepage from "./pages/Homepage/Homepage";
import Impressum from "./pages/Impressum/Impressum";
import Datenschutz from "./pages/Datenschutz/Datenschutz";
import ErrorPage from "./pages/404/ErrorPage";
import BaseCookiebox from "./components/common/BaseCookiebox";
import ReferenzPage from "./pages/ReferenzPage/ReferenzPage";
import InseratePage from "./pages/InseratePage/InseratePage";
import { useQuery } from "@tanstack/react-query";
import { getLogo } from "./api/apiCalls";
import InseratDetailPage from "./pages/InseratDetailPage/InseratDetailPage";

const GA_TRACKING_ID = "X-2FJS7NAF7A"; // hier ANPASSEN

function App() {
  const [visible, setVisible] = useState(false);
  const [presence, setPresence] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("926619ac-8abf-5eeb-bbf7-ebbfcbbe3d8c") === null) {
      console.log("call");
      setPresence(true);
      setVisible(true);
    } else if (localStorage.getItem("926619ac-8abf-5eeb-bbf7-ebbfcbbe3d8c")) {
      enableAnalytics();
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem("926619ac-8abf-5eeb-bbf7-ebbfcbbe3d8c", "true"); // das anpassen!
    setVisible(!visible);
    setTimeout(() => {
      setPresence(!presence);
    }, 550);
    enableAnalytics();
  };

  const handleAcceptNecessary = () => {
    setVisible(!visible);
    setTimeout(() => {
      setPresence(!presence);
    }, 550);
  };

  // enables Google Analytics
  // hier ANPASSEN
  const enableAnalytics = () => {
    ReactGA.initialize(GA_TRACKING_ID);
  };
  const { error, data, isLoading } = useQuery({
    queryKey: ["logoData"],
    queryFn: getLogo,
  });

  return (
    <main className="relative overflow-y-scroll h-screen font-plain  overflow-x-hidden select-text">
      {presence && (
        <BaseCookiebox
          visible={visible}
          handleAcceptAll={handleAcceptAll}
          handleAcceptNecessary={handleAcceptNecessary}
        />
      )}
      <div className={"flex flex-col w-full min-h-screen"}>
        <Header isLoading={isLoading} error={error} data={data} />
        <Routes>
          <Route
            path="/"
            element={<BaseLayout title="TS Template" children={<Homepage />} />}
          />
          <Route
            path="/referenzen"
            element={
              <BaseLayout title="Referenzen" children={<ReferenzPage />} />
            }
          />
          <Route
            path="/inserate"
            element={
              <BaseLayout title="Inserate" children={<InseratePage />} />
            }
          />
          <Route
            path="/inserate/:id"
            element={
              <BaseLayout title="Inserat" children={<InseratDetailPage />} />
            }
          />
          <Route
            path="/impressum"
            element={<BaseLayout title="Impressum" children={<Impressum />} />}
          />
          <Route
            path="/datenschutz"
            element={
              <BaseLayout title="Datenschutz" children={<Datenschutz />} />
            }
          />
          <Route
            path="/404"
            element={
              <BaseLayout title="Page Not Found" children={<ErrorPage />} />
            }
          />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
        <Footer error={error} isLoading={isLoading} data={data} />
      </div>
    </main>
  );
}

export default App;
