import { ReactNode, useMemo } from "react";
import { IMG_CMS } from "../../../api/constants";
import BaseHeadline from "../../../components/common/BaseHeadline";

export default function Leistungen({ data }: any) {
  const leistungenDisplay = useMemo(() => {
    return data.Leistung.sort((a: any, b: any) => b.Position - a.Position);
  }, [data.Leistung]);
  return (
    <section
      id="leistungen"
      className="w-full px-4 sm:px-8 py-16 sm:py-24 md:py-32 max-w-screen-xl flex flex-col justify-start"
    >
      <div className="flex flex-col lg:flex-row lg:gap-16 items-center pb-8">
        <BaseHeadline
          Headline={data.Headline}
          Subheadline={data.Subheadline}
          classProps="items-center lg:items-start text-center lg:text-start"
          lineProps="mx-auto lg:mx-0"
        />
        <div className="w-full lg:w-3/5 flex flex-col items-center justify-center py-8 text-center lg:text-start">
          <p className="font-semibold">{data.Beschreibung}</p>
        </div>
      </div>
      <div className="flex flex-wrap-reverse w-full  gap-8 items-center justify-center">
        {leistungenDisplay.map(
          (item: {
            Headline: string;
            Icon: { data: { attributes: { url: string } } };
            Beschreibung: string;
          }) => (
            <LeistungenCard
              icon={
                <img
                  alt={item.Headline}
                  src={IMG_CMS + item.Icon.data.attributes.url}
                  className="h-12 w-12"
                />
              }
              title={item.Headline}
              description={item.Beschreibung}
            />
          )
        )}
      </div>
    </section>
  );
}

type LeistungenCardType = {
  icon: ReactNode;
  title: string;
  description: string;
};

function LeistungenCard(props: LeistungenCardType) {
  return (
    <div className="w-96 h-56 rounded-md flex flex-col bg-base-100 shadow-lg shadow-primary justify-center items-center">
      {props.icon}
      <h4 className="pt-2 text-xl font-headline uppercase text-center tracking-wider font-bold">
        {props.title}
      </h4>
      <div className="border-t border-secondary w-24 mb-1" />
      <p className="text-sm font-semibold px-8 text-center">
        {props.description}
      </p>
    </div>
  );
}
