import axios from "axios";
import { BACKEND, CDS_API_KEY, CDS_URL } from "./constants";
import { ContactFormBody } from "../types";

export const getHero = () =>
  axios
    .get(BACKEND + "/Hero?populate=*")
    .then((res) => res?.data?.data?.attributes);

export const getLogo = () =>
  axios
    .get(BACKEND + "/logo?populate=*")
    .then((res) => res?.data?.data?.attributes);

export const getLeistungen = () =>
  axios
    .get(BACKEND + "/ueber-uns?populate[Leistung][populate]=*")
    .then((res) => res?.data?.data?.attributes);

export const getRegion = () =>
  axios
    .get(BACKEND + "/region?populate=*")
    .then((res) => res?.data?.data?.attributes);

export const getKontakt = () =>
  axios
    .get(BACKEND + "/Kontakt?populate=*")
    .then((res) => res?.data?.data?.attributes);

export const getInserate = () =>
  axios.get(BACKEND + "/inserates?populate=*").then((res) => res?.data?.data);

export const getSingleInserat = (id: any) =>
  axios
    .get(BACKEND + `/inserates/${id}?populate=*`)
    .then((res) => res?.data?.data?.attributes);

export const getReferenzen = () =>
  axios.get(BACKEND + "/referenzens?populate=*&sort=Nr").then((res) => res?.data?.data);

export const getHeroReferenzen = () =>
  axios
    .get(BACKEND + "/hero-referenzen?populate=*")
    .then((res) => res?.data?.data?.attributes);

export const getHeroInserate = () =>
  axios
    .get(BACKEND + "/hero-inserate?populate=*")
    .then((res) => res?.data?.data?.attributes);

export const getImpressum = () =>
  axios
    .get(BACKEND + "/Impressum?populate=*")
    .then((res) => res?.data?.data?.attributes?.Text);

export const getDatenschutz = () =>
  axios
    .get(BACKEND + "/Datenschutz?populate=*")
    .then((res) => res?.data?.data?.attributes?.Text);


export const sendContactForm = async (data: ContactFormBody) =>
{

  if (!CDS_URL) return Promise.reject("CDS URL config insufficient");
  if (!CDS_API_KEY) return Promise.reject("CDS config insufficient");
  return axios.post(CDS_URL , data, {
    headers: {
      'Content-Type': 'application/json',
                    'x-auth': CDS_API_KEY,
    },
  });
}