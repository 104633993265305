import { motion } from "framer-motion";

export default function BaseCookiebox(props: any) {
  return (
    <motion.div
      initial={{
        y: 0,
        opacity: 1,
      }}
      animate={{
        y: !props.visible ? "10%" : 0,
        opacity: !props.visible ? 0 : 1,
      }}
      transition={{
        ease: "linear",
        duration: 0.3,
      }}
      className={`flex fixed bottom-0 right-0 w-full h-fit bg-base-300/90 justify-center z-[1000]`}
    >
      <div className="w-full max-w-screen-xl flex-col justify-center p-8">
        <h4 className="text-lg sm:text-xl md:text-2xl font-bold text-base-200 underline pb-2">
          Diese Website nutzt Cookies & Google Analytics!
        </h4>
        <p className="text-base-200 text-xs">
          Wir verwenden Cookies und andere Technologien auf unserer Website.
          Einige von ihnen sind essenziell, während andere uns helfen, diese
          Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten
          können verarbeitet werden (z. B. IP-Adressen), z. B. für
          personalisierte Anzeigen und Inhalte oder Anzeigen- und
          Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer Daten
          finden Sie in unserer Datenschutzerklärung. Sie können Ihre Auswahl
          jederzeit unter Einstellungen widerrufen oder anpassen.
        </p>
        <div className="flex flex-col-reverse justify-center items-center sm:flex-row w-full sm:justify-between mt-6">
          <button
            onClick={props.handleAcceptNecessary}
            className="text-white/40 hover:text-white/30"
          >
            Nur notwendige zulassen
          </button>
          <button
            onClick={props.handleAcceptAll}
            className="btn btn-success btn-outline w-48 mb-4 sm:mb-0"
          >
            Bestätigen
          </button>
        </div>
      </div>
    </motion.div>
  );
}
