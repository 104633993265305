import React from "react";
import ErrorLoading from "../../components/common/ErrorLoading";
import { HashLink } from "react-router-hash-link";

export default function ErrorPage() {
  return (
    <div className="flex flex-col justify-center items-center h-[70vh]">
      <ErrorLoading />
      <h6 className="pt-2 text-lg uppercase font-bold underline font-headline">
        UPS - das war wohl Nix!
      </h6>
      <p className="max-w-sm text-center">
        Diese Seite ist aktuell nicht verfügbar.
      </p>
    </div>
  );
}
