import React from "react";
import { HashLink } from "react-router-hash-link";
import { IMG_CMS } from "../../api/constants";

export default function Footer(props: any) {
  return (
    <>
      {!props.isLoading && !props.error && props.data && (
        <div className="flex flex-col items-center justify-center bg-base-300 pb-6">
          <div className="w-full h-fit lg:h-48 flex px-8 items-center justify-center">
            <div className="w-full max-w-screen-xl pt-8 lg:pt-0 flex flex-col lg:flex-row h-full items-center justify-between">
              <img
                src={IMG_CMS + props.data.Logo.data.attributes.url}
                alt="Logo"
                className="w-64 h-12"
              />
              <div className="flex flex-wrap md:flex-row  md:justify-normal text-base-100 pt-4 lg:pt-0 gap-y-8 gap-x-16 md:gap-24 lg:gap-32">
                <div className="flex flex-col gap-2">
                  <p className="text-lg tracking-widest font-semibold sm:pb-4 font-headline">
                    Mehr Sehen
                  </p>
                  <HashLink
                    className="hover:underline text-sm w-fit"
                    to="/inserate"
                  >
                    Inserate
                  </HashLink>
                  <HashLink
                    className="hover:underline text-sm w-fit"
                    to="/referenzen"
                  >
                    Referenzen
                  </HashLink>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-lg tracking-widest font-semibold sm:pb-4 font-headline">
                    Mehr Erfahren
                  </p>
                  <HashLink className="hover:underline text-sm w-fit" to="/">
                    Home
                  </HashLink>
                  <HashLink
                    className="hover:underline text-sm w-fit"
                    to="/impressum"
                  >
                    Impressum
                  </HashLink>
                  <HashLink
                    className="hover:underline text-sm w-fit"
                    to="/datenschutz"
                  >
                    Datenschutz
                  </HashLink>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-lg tracking-widest font-semibold sm:pb-4 font-headline">
                    Direkt Kontaktieren
                  </p>
                  <a className="hover:underline text-sm w-fit" href={"mailto:"+props.data.Mail}>
                    E-Mail: {props.data.Mail}
                  </a>
                  <a className="hover:underline text-sm w-fit" href={"tel:"+props.data.Telefonnummer}>
                    Tel.: {props.data.Telefonnummer}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-[90%] lg:w-full max-w-screen-xl border-b border-base-100 my-6 lg:my-4" />
          <p className="text-xs text-base-100 tracking-wider">
            © 2024 Elsterral GmbH - designed by{" "}
            <span className="underline">
              <a href="https://wicode.io">Wicode</a>
            </span>
          </p>
        </div>
      )}
    </>
  );
}
