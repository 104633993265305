import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";
import { IMG_CMS } from "../../api/constants";
import { ReactComponent as MENU } from "../../assets/Menu.svg";
import { ReactComponent as X } from "../../assets/X.svg";
import { useState } from "react";

export default function Header(props: any) {
  const [openDrawer, setopenDrawer] = useState(false);
  const handleNavigation = () => {
    setopenDrawer(!openDrawer);
  };

  return (
    <motion.nav
      animate={{ backgroundColor: "#2b4475", color: "#fff" }}
      transition={{ duration: 3, delay: 3 }}
      className="navbar bg-base-200/80 fixed z-50 shadow-md p-0 h-20"
    >
      <div className="flex justify-center items-center w-full">
        <ul className="flex justify-between md:justify-center gap-4 lg:gap-0 lg:justify-between px-1 font-semibold text-base items-center w-full max-w-screen-xl">
          <li className="hidden md:block px-4 hover:text-white hover:bg-white/10 py-3 rounded-md">
            <HashLink to="/#_">Home</HashLink>
          </li>
          <li className="hidden lg:block px-4 hover:text-white hover:bg-white/10 py-3 rounded-md">
            <HashLink to="/#leistungen">Leistungen</HashLink>
          </li>
          <li className="hidden md:block lg:hidden px-4 hover:text-white hover:bg-white/10 py-3 rounded-md">
            <HashLink to="/referenzen">Referenzen</HashLink>
          </li>
          <li className="hidden lg:block px-4 hover:text-white hover:bg-white/10 py-3 rounded-md">
            <HashLink to="/#region">Region</HashLink>
          </li>
          {!props.error && !props.isLoading && props.data && (
            <li className="mx-4">
              <HashLink
                to={"/#_"}
                className="btn btn-ghost normal-case text-xl cursor-pointer"
              >
                <img
                  src={IMG_CMS + props.data.Logo.data.attributes.url}
                  alt="Logo"
                  className="w-40 h-8"
                />
              </HashLink>
            </li>
          )}
          <li className="hidden lg:block px-4 hover:text-white hover:bg-white/10 py-3 rounded-md">
            <HashLink to="/referenzen">Referenzen</HashLink>
          </li>
          <li className="hidden md:block px-4 hover:text-white hover:bg-white/10 py-3 rounded-md">
            <HashLink to="/inserate">Inserate</HashLink>
          </li>
          <li className="hidden md:block px-4 ">
            <HashLink to="/#kontakt" className="btn btn-sm btn-secondary">
              Kontakt
            </HashLink>
          </li>
          <li className="md:hidden px-4 flex items-center">
            <button onClick={handleNavigation}>
              <MENU className="w-10 h-10" />
            </button>
          </li>
        </ul>
      </div>

      <motion.ul
        initial={{ x: "200%" }}
        animate={{ x: openDrawer ? 0 : "200%" }}
        transition={{ duration: 0.5, type: "ease" }}
        className="absolute flex flex-col py-16 w-80 h-screen bg-primary shadow-black shadow-lg top-0 right-0 z-50 items-start text-lg font-semibold px-4"
      >
        <button onClick={handleNavigation} className=" absolute top-8 right-8">
          <X className="w-10 h-10" />
        </button>
        <li
          onClick={handleNavigation}
          className="py-3 w-full px-4 border-b border-white/50"
        >
          <HashLink to="/#_">Home</HashLink>
        </li>
        <li
          onClick={handleNavigation}
          className="py-3 w-full px-4 border-b border-white/50"
        >
          <HashLink to="/#leistungen">Leistungen</HashLink>
        </li>
        <li
          onClick={handleNavigation}
          className="py-3 w-full px-4 border-b border-white/50"
        >
          <HashLink to="/#region">Region</HashLink>
        </li>
        <li
          onClick={handleNavigation}
          className="py-3 w-full px-4 border-b border-white/50"
        >
          <HashLink to="/inserate">Inserate</HashLink>
        </li>
        <li
          onClick={handleNavigation}
          className="py-3 w-full px-4 border-b border-white/50"
        >
          <HashLink to="/referenzen">Referenzen</HashLink>
        </li>
        <li onClick={handleNavigation} className="py-3 w-full px-4">
          <HashLink to="/#kontakt">Kontakt</HashLink>
        </li>
      </motion.ul>
    </motion.nav>
  );
}
