import React from "react";
import { IMG_CMS } from "../../../api/constants";
import { BlocksContent, BlocksRenderer } from "@strapi/blocks-react-renderer";
import BaseHeadline from "../../../components/common/BaseHeadline";

export default function Region({ data }: any) {
  const content: BlocksContent = data.Text;

  return (
    <section
      style={{
        backgroundImage: `url(${IMG_CMS + data?.Image?.data.attributes.url})`,
      }}
      id="region"
      className="w-full relative flex h-full lg:h-[70vh] bg-fixed bg-cover bg-center"
    >
      <div className="w-full h-full lg:h-[70vh] absolute bg-base-300/10" />
      <div className="w-full lg:w-3/5 xl:w-1/2 lg:absolute lg:top-0 lg:right-0 h-full bg-base-200/80 px-4 sm:px-8 py-16 sm:p-16 md:p-24 justify-center flex flex-col">
        <BaseHeadline Headline={data.Headline} Subheadline={data.Subheadline} />
        <div className="pt-1.5 lg:hidden" />
        <BlocksRenderer content={content ?? []} />
      </div>
    </section>
  );
}
