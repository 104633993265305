export function HardFact(props: any) {
  return (
    <div className="flex flex-col items-center">
      {props.icon}
      <p
        className={`${
          props.smallText ? "text-xs" : "text-sm"
        } text-center font-semibold`}
      >
        {props.number}
        {props.title}
      </p>
    </div>
  );
}
