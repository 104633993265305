import { HashLink } from "react-router-hash-link";
import { IMG_CMS } from "../../../api/constants";

export default function InseratHero(props: any) {
  return (
    <section className="flex flex-col lg:flex-row w-full gap-8 h-fit lg:h-[60vh] pt-2 md:pt-16 max-w-screen-2xl">
      {props.video ? (
        <div className="h-96 w-full lg:w-2/3 lg:h-full bg-cover bg-center rounded-lg">
          <video
            src={IMG_CMS + props.video}
            autoPlay
            loop
            muted
            className="w-full h-full object-cover"
          ></video>
        </div>
      ) : (
        <div
          className="h-96 w-full lg:w-2/3 lg:h-full bg-cover bg-center rounded-lg"
          style={{ backgroundImage: `url(${IMG_CMS + props.imgUrl})` }}
        ></div>
      )}
      <div className="lg:w-fit lg:max-w-[45%] xl:w-1/3 h-full gap-4 flex flex-col lg:py-8 justify-center items-center lg:items-start">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-headline text-center lg:text-start">
          {props.Headline}
        </h1>
        <p className="text-base sm:text-lg md:text-xl font-semibold text-center lg:text-start">
          {props.description}
        </p>
        <div className="flex gap-4 items-center ">
          <HashLink to="#kontakt" className="btn btn-primary w-fit">
            Direkt Anfragen
          </HashLink>
        </div>
      </div>
    </section>
  );
}
