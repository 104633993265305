import React, { useState } from "react";
import BaseHeadline from "../../../components/common/BaseHeadline";
import { Carousel } from "antd";
import { IMG_CMS } from "../../../api/constants";
import "../../../style/carousel.css";
import { ReactComponent as RIGHTARROW } from "../../../assets/RightArrow.svg";
import { HardFact } from "../../InseratePage/components/HardFact";
import { ReactComponent as SPACE } from "../../../assets/Space.svg";
import { ReactComponent as FLOOR } from "../../../assets/OfficeBuilding.svg";
import { ReactComponent as ROOM } from "../../../assets/House.svg";
import { ReactComponent as CHECK } from "../../../assets/Check.svg";
import { HashLink } from "react-router-hash-link";

export default function Inserate(props: any) {
  const [inseratId, setinseratId] = useState<string>(props.inserate[0].id);

  const handleItemChange = (index: any) => {
    setinseratId(props.inserate[index].id);
  };
  return (
    <>
      {props.inserate !== undefined && (
        <section
          id="inserate"
          className="w-full px-4 sm:px-8 bg-primary flex flex-col justify-center items-center py-16 sm:py-24 md:py-32 relative"
        >
          <div className="w-full max-w-screen-xl overflow-hidden">
            <BaseHeadline Headline="Inserate" classProps="text-base-100" />
            <Carousel
              arrows
              draggable
              infinite
              autoplay
              autoplaySpeed={8000}
              dots={{
                className: "custom-dots",
              }}
              afterChange={(index) => handleItemChange(index)}
            >
              {props.inserate?.map((item: any) => (
                <Inserat
                  key={item.id}
                  Headline={item?.attributes?.Headline}
                  Adresse={item?.attributes?.Adresse}
                  Beschreibung={item?.attributes?.Kurzbeschreibung}
                  url={item?.attributes?.Bilder}
                  Zimmer={item?.attributes?.Zimmer}
                  Wohnflaeche={item?.attributes?.Wohnflaeche}
                  Stockwerk={item?.attributes?.Stockwerk}
                  Barrierefrei={item?.attributes?.Barrierefrei}
                />
              ))}
            </Carousel>
            <div className="flex  flex-row w-full items-center justify-center z-10 h-12 relative">
              <HashLink
                to={"/inserate/" + inseratId}
                className="btn w-4/5 sm:w-[32rem] lg:w-fit lg:btn-sm self-end btn-secondary absolute lg:bottom-8 lg:right-8"
              >
                Zeig es mir!
                <RIGHTARROW className="w-4 h-4" />
              </HashLink>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

function Inserat(props: any) {
  return (
    <div className="w-full px-8 max-h-full h-fit lg:h-80 text-base-100 flex flex-col lg:flex-row p-4 lg:gap-8 items-center">
      {props.url.data !== null && (
        <img
          src={IMG_CMS + props.url.data[0].attributes.url}
          alt={"Coverbild" + props.Headline}
          className="w-full sm:w-[32rem] h-80 lg:h-full object-cover rounded-lg"
        />
      )}
      <div className="flex flex-col w-full sm:w-[32rem] px-8 lg:px-0 lg:w-full lg:pr-16 pt-4 lg:pt-8 items-center lg:items-start">
        <div className="flex flex-col w-fit">
          <p className="text-sm">{props.Adresse}</p>
          <div className="border-b border-secondary w-[120%] my-2" />
        </div>
        <h5 className="text-xl font-semibold text-center lg:text-start">
          {props.Headline}
        </h5>
        <p className="hidden lg:block text-base pt-4 line-clamp-3">
          {props.Beschreibung}
        </p>
        <div className="flex flex-wrap justify-center sm:flex-row gap-4 sm:gap-12 w-fit py-4 lg:pl-8">
          <HardFact
            icon={<ROOM className="w-8 h-8" />}
            number={props.Zimmer}
            title={" Zimmer"}
          />
          <HardFact
            icon={<SPACE className="w-8 h-8" />}
            number={props.Wohnflaeche}
            title={" m²"}
          />
          <HardFact
            icon={<FLOOR className="w-8 h-8" />}
            number={props.Stockwerk}
            title={" Stock"}
          />
          {props.Barrierefrei && (
            <HardFact
              title={"Barrierefrei"}
              icon={<CHECK className="w-8 h-8 text-white" />}
            />
          )}
        </div>
      </div>
    </div>
  );
}
