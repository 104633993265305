import React from "react";
import BaseHeadline from "../../../components/common/BaseHeadline";
import BaseInput from "../../../components/common/BaseInput";
import { IMG_CMS } from "../../../api/constants";
import { ContactFormBody } from "../../../types";
import { sendContactForm } from "../../../api/apiCalls";
import { useMutation } from "@tanstack/react-query";
import useCookie from "react-use-cookie";
import { MAPS } from "../../../index";

export default function Kontakt(props: any) {
  const [mapsCookie, setMapsCookie] = useCookie(
    MAPS.CONSENT_COOKIE_NAME,
    MAPS.CONSENT_COOKIE_VALUE.disabled
  );
  const [values, setValues] = React.useState<ContactFormBody>({
    name: "",
    phone: "",
    email: "",
    topic: "",
    message: "",
  });

  const {
    mutate: postContactForm,
    error,
    isSuccess,
    isError,
    data,
  } = useMutation({
    mutationFn: sendContactForm,
    onError: (error) => console.log(error),
    mutationKey: ["15e376da-d865-5424-b1bf-ba4ee3f22915"],
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postContactForm(values);
  };

  return (
    <section
      id="kontakt"
      className={`flex w-full gap-16 max-w-screen-xl h-fit items-center relative px-4 sm:px-8 ${
        props.withMap ? "py-16 md:py-24 lg:py-32" : "pt-8"
      } ${props.classProps}`}
    >
      {props.withMap && (
        <div className="hidden lg:block absolute top-16 right-16 bg-secondary rounded-lg p-3">
          <img
            src={IMG_CMS + props?.data?.Icon?.data?.attributes?.url}
            alt="Icon for Contact"
            className="lg:w-12 lg:h-12 xl:w-14 xl:h-14"
          />
        </div>
      )}
      {props.withMap && mapsCookie === MAPS.CONSENT_COOKIE_VALUE.enabled && (
        <>
          <iframe
            title="MapsGrimma"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d778.2298865697015!2d12.727306268575097!3d51.23487353333039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a7042c53823b4d%3A0xc55b7a893c54623!2sLange%20Str.%2042%2C%2004668%20Grimma!5e1!3m2!1sde!2sde!4v1732258434891!5m2!1sde!2sde"
            className="w-full h-96 hidden lg:block"
            loading="lazy"
          ></iframe>
          <div className="hidden lg:block border-l-2 border-primary h-96" />
        </>
      )}
      {props.withMap && mapsCookie === MAPS.CONSENT_COOKIE_VALUE.disabled && (
        <div className="hidden lg:flex w-full h-96 bg-base-200 items-center justify-center">
          <button
            onClick={() => setMapsCookie(MAPS.CONSENT_COOKIE_VALUE.enabled)}
            className="bg-secondary cursor-pointer text-white rounded-2xl py-2 px-6 font-normal active:scale-pressed duration-100 ease-in-out text-center"
          >
            Google Maps Karte anzeigen
          </button>
        </div>
      )}
      <div className="flex flex-col w-full h-fit ">
        <BaseHeadline
          Headline={props.data?.Headline}
          Subheadline={props.data?.Subheadline}
          lineProps="mt-4"
        />
        <form
          className="relative flex flex-col sm:flex-row lg:flex-wrap gap-4 pt-4 w-full"
          onSubmit={handleSubmit}
          autoComplete="on"
        >
          <div
            className={`flex flex-col gap-2 ${
              props.withMap ? "w-full lg:w-48" : "w-full sm:w-1/2"
            }`}
          >
            <BaseInput
              placeholder="Name"
              autocomplete="name"
              onChange={(e: any) =>
                setValues({ ...values, name: e?.target?.value ?? "" })
              }
            />
            <BaseInput
              placeholder="Tel."
              id="phone"
              name="phone"
              onChange={(e: any) =>
                setValues({ ...values, phone: e?.target?.value ?? "" })
              }
              disabled={isSuccess}
            />
            <BaseInput
              placeholder="E-Mail"
              type="email"
              id="email"
              name="email"
              onChange={(e: any) =>
                setValues({ ...values, email: e?.target?.value ?? "" })
              }
              disabled={isSuccess}
            />
            <BaseInput
              value={props.topicContact}
              placeholder="Thema"
              id="topic"
              name="topic"
              onChange={(e: any) =>
                setValues({ ...values, topic: e?.target?.value ?? "" })
              }
              disabled={isSuccess || props.topicContact}
            />
          </div>
          <textarea
            className={`textarea textarea-primary ${
              props.withMap ? "w-full lg:w-48" : "grow"
            }`}
            placeholder="Beschreibung"
            onChange={(e: any) =>
              setValues({ ...values, message: e?.target?.value ?? "" })
            }
            disabled={isSuccess}
          ></textarea>
          {isSuccess && (
            <div className="text-primary w-full ">
              Ihre Nachricht wurde erfolgreich versendet!
            </div>
          )}

          {isError && (
            <div className="text-primary w-full">
              {(error as any)?.response?.data?.DisplayMessage ??
                "Ihre Nachricht konnte nicht versendet werden!"}
            </div>
          )}
          <button
            className={`btn sm:btn-sm btn-primary self-end w-full sm:w-48 lg:w-32 ${
              !props.withMap && "sm:absolute sm:-bottom-10 sm:right-0"
            } ${isSuccess && "hidden"}`}
            type="submit"
          >
            Senden
          </button>
        </form>
      </div>
    </section>
  );
}
