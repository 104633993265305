import { useQuery } from "@tanstack/react-query";
import React from "react";
import {
  getHero,
  getInserate,
  getKontakt,
  getLeistungen,
  getReferenzen,
  getRegion,
} from "../../api/apiCalls";
import Hero from "./components/Hero";
import Leistungen from "./components/Leistungen";
import Region from "./components/Region";
import Kontakt from "./components/Kontakt";
import Loading from "../../components/common/Loading";
import ErrorPage from "../404/ErrorPage";
import Referenzen from "./components/Referenzen";
import Inserate from "./components/Inserate";

export default function Homepage() {
  const {
    error: heroError,
    data: hero,
    isLoading: heroLoading,
  } = useQuery({
    queryKey: ["heroData"],
    queryFn: getHero,
  });

  const {
    error: leistungenError,
    data: leistungen,
    isLoading: leistungenLoading,
  } = useQuery({
    queryKey: ["leistungenData"],
    queryFn: getLeistungen,
  });

  const {
    error: regionError,
    data: region,
    isLoading: regionLoading,
  } = useQuery({
    queryKey: ["regionData"],
    queryFn: getRegion,
  });

  const {
    error: kontaktError,
    data: kontakt,
    isLoading: kontaktLoading,
  } = useQuery({
    queryKey: ["kontaktData"],
    queryFn: getKontakt,
  });
  const {
    error: inserateError,
    data: inserate,
    isLoading: inserateLoading,
  } = useQuery({
    queryKey: ["inserateData"],
    queryFn: getInserate,
  });
  const {
    error: referenzenError,
    data: referenzen,
    isLoading: referenzenLoading,
  } = useQuery({
    queryKey: ["referenzenData"],
    queryFn: getReferenzen,
  });

  return (
    <main className="w-full h-full flex flex-col items-center justify-center">
      {(heroLoading ||
        kontaktLoading ||
        regionLoading ||
        leistungenLoading ||
        referenzenLoading ||
        inserateLoading) && <Loading />}
      {(heroError ||
        kontaktError ||
        regionError ||
        leistungenError ||
        referenzenError ||
        inserateError) && <ErrorPage />}
      {hero && kontakt && region && leistungen && referenzen && inserate && (
        <>
          <Hero data={hero} />
          <Leistungen data={leistungen} />
          {(inserate === undefined || inserate.length === 0) && (
            <Referenzen referenzen={referenzen} />
          )}
          {inserate.length > 0 && <Inserate inserate={inserate} />}
          <Region data={region} />
          {inserate.length > 0 && <Referenzen referenzen={referenzen} />}
          <Kontakt data={kontakt} withMap />
        </>
      )}
    </main>
  );
}
