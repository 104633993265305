import { ReactTyped } from "react-typed";
import { IMG_CMS } from "../../../api/constants";
import { useMemo } from "react";
import { HashLink } from "react-router-hash-link";

export default function Hero(data: any) {
  const TypoStrings = useMemo(
    () => data.data.Headline.map((item: { Text: string }) => item.Text),
    [data.data.Headline]
  );
  return (
    <section
      id="_"
      className="w-full h-[70vh] px-8 flex flex-col items-center justify-center overflow-hidden"
    >
      <img
        src={IMG_CMS + data?.data?.Hintergrundbild?.data?.attributes?.url}
        alt=""
        className="absolute top-0 -z-20 w-full h-[70vh] object-cover"
      />
      <div className="w-full h-[70vh] absolute -z-10 bg-base-300/30" />
      <div className="w-full max-w-screen-xl text-base-100">
        <h1 className="text-5xl font-bold font-headline">
          <ReactTyped
            strings={TypoStrings}
            typeSpeed={60}
            backSpeed={30}
            showCursor={true}
            backDelay={2000}
            loop
          />
        </h1>
        <div className="border-t-2 border-secondary w-80 mt-4" />
        <h2 className="text-lg font-semibold w-full sm:w-[36rem] py-4">
          {data?.data?.Beschreibung}
        </h2>
        <HashLink to="/#kontakt" className="btn btn-sm btn-secondary">
          Jetzt Kontaktieren
        </HashLink>
      </div>
    </section>
  );
}
