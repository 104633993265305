import { useQuery } from "@tanstack/react-query";
import { getHeroReferenzen, getReferenzen } from "../../api/apiCalls";
import Hero from "../Homepage/components/Hero";
import Loading from "../../components/common/Loading";
import ErrorPage from "../404/ErrorPage";
import ReferenzDetailObj from "./components/ReferenzDetailObj";

export default function ReferenzPage() {
  const {
    error: heroError,
    data: hero,
    isLoading: heroLoading,
  } = useQuery({
    queryKey: ["heroDataReferenzen"],
    queryFn: getHeroReferenzen,
  });

  const {
    error: referenzenError,
    data: referenzen,
    isLoading: referenzenLoading,
  } = useQuery({
    queryKey: ["referenzenData"],
    queryFn: getReferenzen,
  });

  return (
    <main className="w-full h-full flex flex-col items-center justify-center">
      {(heroError || referenzenError) && <ErrorPage />}
      {(heroLoading || referenzenLoading) && <Loading />}
      {hero && referenzen && (
        <>
          <Hero data={hero} />
          {referenzen.map((item: any, index: number) => (
            <>
              <ReferenzDetailObj data={item} index={index} />
              <div className="border-b border-primary w-[95%] md:w-[65%] " />
            </>
          ))}
        </>
      )}
    </main>
  );
}
