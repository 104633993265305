import { ReactNode } from "react";
import { Helmet } from "react-helmet";

export type BaseLayoutType = {
  title: string;
  children: ReactNode;
};

export default function BaseLayout({ title, children }: BaseLayoutType) {
  return (
    <section className="grow w-full flex flex-col items-center justify-start">
      <Helmet>
        <title>Elsterral GmbH | Bauen & Verwaltung</title>
        <meta
          name="description"
          content="
          Ihr regionaler Bauträger in Grimma! Wir bauen, verwalten und sanieren
          für Sie!
        "
        />
        <meta
          name="keywords"
          content="
          Bauträger, Hausverwaltung, Sanierung, Dachdecker, Dach, Dachbau,
          Grimma, Leipzig"
        />
      </Helmet>
      {children}
    </section>
  );
}
